import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Under Construction......
        </p>
        <p>
          Online Resume
        </p>
        <p>
          of
        </p>
        <p>
          Tilak Acharya
        </p>
      </header>
    </div>
  );
}

export default App;
